import { Controller } from "@hotwired/stimulus";
import { publicPaymentsAPIV1ProviderPayments } from "@api";
import { getPublicKey } from "@helper/utils";

export default class extends Controller {
  static targets = ["form", "customMessage"];

  static values = {
    clientId: String,
    requestId: String,
    signature: String,
    transactionNo: String,
  };

  initialize() {
    this.rerun = true;
    this.attampt = 0;
    this.intervalValue = 10000;
  }

  connect() {
    this._fetchPayment();

    if (this.rerun) {
      this._startFetchingPayment();
    }
  }

  disconnect() {
    this._stopFetchingPayment();
  }

  _startFetchingPayment() {
    this.fetchingAttampt = setInterval(() => {
      this._fetchPayment();
    }, this.intervalValue);
  }

  _stopFetchingPayment() {
    if (this.fetchingAttampt) {
      clearInterval(this.fetchingAttampt);
    }
  }

  async _fetchPayment() {
    this.attampt++;
    if (this.attampt > 3) {
      this._stopFetchingPayment();
      return;
    }

    let params = {
      provider_payment: {
        clientId: this.clientIdValue,
        requestId: this.requestIdValue,
        signature: this.signatureValue,
        transactionNo: this.transactionNoValue,
      },
    };

    try {
      const response = await publicPaymentsAPIV1ProviderPayments.create({
        headers: {
          apiKey: getPublicKey(),
        },
        data: params,
      });

      if (response.data.status == true) {
        const iframe = this._buildIFrame(response.data.url);
        this.formTarget.replaceChild(iframe, this.formTarget.children[0]);

        this.rerun = false;
        this._stopFetchingPayment();
      }
    } catch (error) {
      this.customMessageTarget.innerText = error.body.message;
    }
  }

  _buildIFrame(frameUrl) {
    const iframe = document.createElement("iframe");

    iframe.style = "height: 24rem; width: 100%";
    iframe.setAttribute("src", frameUrl);

    return iframe;
  }
}
