// JsFromRoutes CacheKey 41a286aabc6b20b2a51a9338fc8c249b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  list: definePathHelper('get', '/profiles'),
  create: definePathHelper('post', '/profiles'),
  new: definePathHelper('get', '/profiles/new'),
  edit: definePathHelper('get', '/profiles/:id/edit'),
  get: definePathHelper('get', '/profiles/:id'),
  update: definePathHelper('patch', '/profiles/:id'),
  destroy: definePathHelper('delete', '/profiles/:id'),
}
