// JsFromRoutes CacheKey 25426dfafb431e43cd8acf8773afdb25
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  cancel: definePathHelper('get', '/users/cancel'),
  new: definePathHelper('get', '/users/sign_up'),
  edit: definePathHelper('get', '/users/edit'),
  update: definePathHelper('patch', '/users'),
  destroy: definePathHelper('delete', '/users'),
  create: definePathHelper('post', '/users'),
}
