// JsFromRoutes CacheKey 370796f96b25d213aee4197d8415c373
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  list: definePathHelper('get', '/integration_apps'),
  create: definePathHelper('post', '/integration_apps'),
  new: definePathHelper('get', '/integration_apps/new'),
  edit: definePathHelper('get', '/integration_apps/:id/edit'),
  get: definePathHelper('get', '/integration_apps/:id'),
  update: definePathHelper('patch', '/integration_apps/:id'),
  destroy: definePathHelper('delete', '/integration_apps/:id'),
}
