// JsFromRoutes CacheKey d2ecffff82504a0ac2634bd8319a2fea
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: definePathHelper('get', '/users/password/new'),
  edit: definePathHelper('get', '/users/password/edit'),
  update: definePathHelper('patch', '/users/password'),
  create: definePathHelper('post', '/users/password'),
}
