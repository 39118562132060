// JsFromRoutes CacheKey aa856c550e75b0080c6b0dc2180e03c7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: definePathHelper('get', '/users/sign_in'),
  create: definePathHelper('post', '/users/sign_in'),
  destroy: definePathHelper('delete', '/users/sign_out'),
}
